<template>
  <v-tab @click="toggleSearchBar">
    搜尋
  </v-tab>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    keyword: null, 
  }),
  methods: {
    async toggleSearchBar() {
      this.$store.commit(`list/${this.listKey}/setSearchBarStatus`, !this.searchBarStatus)
    },
  },
  computed: {
    searchBarStatus() {
      return this.$store.getters[`list/${this.listKey}/searchBarStatus`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
  },
  watch: {
    searchKeyword: {
      immediate: true,
      handler() {
        this.keyword = this.searchKeyword;
      },
    },
  },
}
</script>